<template lang="pug">
div
  .thank-you-box
    .thank-container
      b-row
        b-col.text-center
          img(:src="wuwowThankyouPng" alt="WUWOW 線上英語教學 感謝您")
      b-row.mt-5
        b-col
          span.thank-you-content
            | 謝謝您提供的訊息，我們已經收到您的資料！
            br
            | 我們會盡快安排專員與您聯絡！
      b-row.mt-5
        b-col.thank-button-group
          b-button.go-back-home-btn.m-bottom-5.btn-hover( v-if="hasWritedPhone" variant="primary" href="https://lihi2.com/EeySZ" target="_blank") 領取 敦煌專屬兒美單字書 (附音檔)
          b-button.go-back-home-btn(@click="backToHome()" variant="outline-primary") 返回WUWOW Jr官網
</template>

<script>
import wuwowThankyouPng from '@/assets/wuwow_ty.png';
export default {
  name: 'ThankYouPage',

  data() {
    return {
      wuwowThankyouPng: wuwowThankyouPng,
      head: null,
      setFreecoinsCvq: null,
      freecoins: null,
      hasWritedPhone: localStorage.getItem('hasWritedPhone') || '',
    };
  },

  created() {
    this.initLinePoint();
  },
  destroyed() {
    this.head.removeChild(this.setFreecoinsCvq);
    this.head.removeChild(this.freecoins);
  },

  methods: {
    backToHome(){
      this.$router.push({ path: '/' });
    },
    initLinePoint() {
      // Line Point 前置作業
      this.head = document.querySelector('head');
      this.setFreecoinsCvq = document.createElement('script');
      this.setFreecoinsCvq.setAttribute('type', 'text/javascript');
      this.setFreecoinsCvq.innerHTML = `
        if(localStorage.getItem('setFreecoinsCvq') == "true") {
          let phone = localStorage.getItem('trialPhone');
          var freecoins_cvq = [
            {
              app: "FREECOINS_33485",
              domain: ".wuwowjr.tw",
              cv: [
                {
                  action: "REGISTRATION",
                  order: phone,
                  item: "trial2023Form",
                  t_price: "1",
                  quantity: "1",
                  memo: "JR官網試讀感謝頁"
                }
              ]
            }
          ];
        }`;
      this.freecoins = document.createElement('script');
      this.freecoins.setAttribute('src', 'https://freecoins.line-apps.com/lfc5.js');
      this.freecoins.setAttribute('async', '');
      this.head.appendChild(this.setFreecoinsCvq);
      this.head.appendChild(this.freecoins);
    },
  },
};
</script>
<style scoped lang="scss">
.thank-you-box{
  height: 100vh;
  text-align: center;
  background-color: #F5F5F5;
  background-image: url(~@/assets/event/caves-education-authorize/thank_you_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding:0 16px
}
.thank-container{
  max-width: 650px;
  margin: 0 auto;
  padding: 40px;
  border-radius: 20px;
  border: 1px solid #FFF;
  background: rgba(255, 255, 255, 0.36);
}
.thank-you-content{
  font-size: 18px;
  color: var( --text-light-color );
}
.thank-button-group {
  max-width: 322px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.m-bottom-5 {
  margin-bottom: 20px;
}
.go-back-home-btn{
  font-size: 16px;
  padding: 15px 0px;
}

.btn-hover{
  &:hover{
    background:#505050;
    border: 1px solid #505050;
  }
}
</style>
